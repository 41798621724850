import request from '@/utils/request'

//支付列表
export function getPayList(params) {
    return request({
        url: '/admin/finance/payList',
        method: 'GET',
        params
    })
}

//支付金额统计
export function getPayCount(params) {
    return request({
        url: '/admin/finance/payCount',
        method: 'GET',
        params
    })
}
//导出
export function getExport(params) {
    return request({
        responseType: 'blob',
        url: '/admin/finance/export',
        method: 'GET',
        params
    })
}
//提现明细
export function getWithdraw(params) {
    return request({
        url: '/admin/finance/withdraw',
        method: 'GET',
        params
    })
}
//提现审核
export function putPass(params) {
    return request({
        url: '/admin/finance/withdraw/pass',
        method: 'put',
        params
    })
}
//提现导出
export function postExport(params) {
    return request({
        responseType: 'blob',
        url: '/admin/finance/withdraw/export',
        method: 'post',
        params
    })
}