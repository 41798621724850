<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div class="search-container-fn-input">
        <label>订单号：</label>
        <el-input
          size="small"
          placeholder="订单号"
          v-model="searchParams.order_sn"
          clearable
          @change="getList('restPage')"
        ></el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
      <!-- <div class="search-container-fn-input">
        <label>手机号：</label>
        <el-input
          size="small"
          placeholder="订单号"
          v-model="searchParams.moblie"
          clearable
          @change="getList('restPage')"
        ></el-input>
      </div> -->
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('export') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleExport"
            >导出订单
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('examine') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handlePass(1, multipleSelectionPres[0].id)"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].status_name == '待审核' &&
                !multipleSelectionPres[0].express_no
            "
            >通过
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('examine') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handlePass(2, multipleSelectionPres[0].id)"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].status_name == '待审核' &&
                !multipleSelectionPres[0].express_no
            "
            >驳回
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('examine') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleTwo(multipleSelectionPres[0].id)"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].status_name == '待审核' &&
                multipleSelectionPres[0].express_no
            "
            >退货退款审核
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click="getInfo(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'type_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.type_name)
                "
                v-model="searchParams.type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'status_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.type_name)
                "
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'username' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.username"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.username)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!-- <el-drawer
        :append-to-body="true"
        size="72%"
        :visible.sync="dialogTableVisible"
        direction="rtl"
      >
        <memberInfo
          ref="memberInfo"
          @getList="getList"
          v-if="dialogTableVisible"
        ></memberInfo>
      </el-drawer> -->
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span style="border-bottom:2px solid #2362FB" slot="title">{{
        status_type == 1 ? "退款金额" : "驳回原因"
      }}</span>
      <el-input
        v-if="status_type == 2"
        v-model="reject_reason"
        type="textarea"
        size="mini"
        :rows="6"
        placeholder="请填写驳回原因"
      ></el-input>
      <div  v-if="status_type == 1">
        <el-input
        v-model="real_refund_amount"
        type=""
        style="width: 200px !important;"
        size="mini"
        placeholder="请输入退款金额"
      ></el-input>
      <span style="margin-left:20px">当前订单最多可退款金额:{{multipleSelectionPres[0].real_refund_amount}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleCon" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="twoDialogVisible" width="30%">
      <span style="border-bottom:2px solid #2362FB" slot="title">二次审核</span>
      <el-radio-group v-model="status" @change="changeRadio" style="margin: 10px 0;">
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">拒绝</el-radio>
      </el-radio-group>
      <el-input
      v-if="status_type == 2"
        :disabled="status == 1"
        v-model="reject_reason"
        type="textarea"
        size="mini"
        :rows="6"
        placeholder="请填写驳回原因"
      ></el-input>
      <div v-if="status_type == 1">
        <el-input
          v-model="real_refund_amount"
          type=""
          style="width: 200px !important;"
          size="mini"
          placeholder="请输入退款金额"
        ></el-input>
        <span style="margin-left:20px"
          >当前订单退款金额:{{ multipleSelectionPres[0].total_amount }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoDialogVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleConTwo" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead";
import {
  getAfterList,
  postExamine,
  postExamineRefund,
  getAfterExport,
} from "@/newApi/order";
import { Message } from "element-ui";
import { mapState } from "vuex";
import {postExport} from "@/newApi/fina";
export default {
  name: "memberList",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res);
      this.operateList = res;
    });
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  data() {
    return {
      table_type: "afterList",
      operateList: [],
      reject_reason: "",
      real_refund_amount: "", //退款金额
      dialogVisible: false,
      dialogTableVisible: false,
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      status: null,
      searchTableHead: [
        //控制搜索
        {
          name: "type_name",
          isSearch: false,
        },
        {
          name: "status_name",
          isSearch: false,
        },
        {
          name: "username",
          isSearch: false,
        },
        {
          name: "order_sn",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      typeList: [
        {
          value: 1,
          label: "仅退款",
        },
        {
          value: 2,
          label: "退货退款",
        },
      ],
      statusList: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "通过",
        },
        {
          value: 2,
          label: "拒绝",
        },
        {
          value: 3,
          label: "完成",
        },
      ],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
      twoDialogVisible: false,
      status_type:null,
    };
  },
  components: {
    Pagination,
    editTableHead,
  },

  methods: {
    changeRadio(e) {
      console.log(e,1111);
      this.status_type = e;
      this.real_refund_amount = this.multipleSelectionPres[0].real_refund_amount;
    },
    //导出订单
    handleExport() {
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      getAfterExport(params).then((response) => {
        console.log(response);
        const href = URL.createObjectURL(response); //创建新的URL表示指定的blob对象
        const a = document.createElement("a"); //创建a标签
        a.style.display = "none";
        a.href = href; // 指定下载链接
        a.download = "格格商城平台售后订单明细表"; //指定下载文件名
        a.click();
      });
      this.listLoading = false;
    },
    //二次审核
    handleConTwo(e) {
      console.log(e);
      if (this.status_type == 1) {
        if (this.real_refund_amount == "") {
          this.$message({
            type: "error",
            message: "请输入退款金额",
          });
          return false;
        }
        if (this.real_refund_amount > this.multipleSelectionPres[0].real_refund_amount) {
          this.$message({
            type: "error",
            message: "退款金额不能大于订单实付金额",
          });
          return false;
        }
        postExamineRefund({
          id:this.multipleSelectionPres[0].id,
          status: 1,
          real_refund_amount: this.real_refund_amount,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "审核成功",
              });
              this.twoDialogVisible = false;
              this.real_refund_amount = "";
              this.getList("restPage");
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      } else {
        if (this.reject_reason == "") {
          this.$message({
            type: "error",
            message: "请输入驳回原因",
          });
          return false;
        }
        postExamineRefund({
          id: this.multipleSelectionPres[0].id,
          status: 2,
          reject_reason: this.reject_reason,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "驳回成功",
              });
              this.twoDialogVisible = false;
              this.reject_reason = "";
              this.getList("restPage");
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      }
    },
    handleTwo(id) {
      console.log(id);
      this.twoDialogVisible = true;
    },
    handleCon() {
      if (this.status_type == 2) {
        if (this.reject_reason == "") {
          this.$message({
            type: "error",
            message: "请输入驳回原因",
          });
          return false;
        }
        postExamine({
          id: this.multipleSelectionPres[0].id,
          status: 2,
          reject_reason: this.reject_reason,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "驳回成功",
              });
              this.dialogVisible = false;
              this.reject_reason = "";
              this.getList("restPage");
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      } else if(this.status_type == 1) {
        if (this.real_refund_amount == "") {
          this.$message({
            type: "error",
            message: "请输入退款金额",
          });
          return false;
        }else if(this.real_refund_amount > this.multipleSelectionPres[0].real_refund_amount){
          this.$message({
            type: "error",
            message: "退款金额不能大于订单实付金额",
          });
          return false;
        }
        postExamine({
          id: this.multipleSelectionPres[0].id,
          status: 1,
          real_refund_amount: this.real_refund_amount,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "退款成功",
              });
              this.dialogVisible = false;
              this.real_refund_amount = "";
              this.getList("restPage");
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      }
    },
    //退款审核
    handlePass(row, id) {
      this.status_type = row;
      if (row == 1) {
        if(this.multipleSelectionPres[0].type_name == '退货退款'){
          //确认提示框
          this.$confirm("是否通过审核？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              postExamine({ id: id, status: 1 })
                .then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      type: "success",
                      message: "审核成功",
                    });
                    this.getList("restPage");
                  }
                })
                .catch((error) => {
                  this.$message({
                    type: "error",
                    message: error.message,
                  });
                });
            })
        }else{
          this.real_refund_amount = this.multipleSelectionPres[0].real_refund_amount;
        this.dialogVisible = true;
        }
        // this.$confirm("是否通过审核？", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(() => {
        //     postExamine({ id: id, status: 1 })
        //       .then((res) => {
        //         if (res.code == 200) {
        //           this.$message({
        //             type: "success",
        //             message: "审核成功",
        //           });
        //           this.getList("restPage");
        //         }
        //       })
        //       .catch((error) => {
        //         this.$message({
        //           type: "error",
        //           message: error.message,
        //         });
        //       });
        //   })
        // .catch(() => {
        //   this.$message({
        //     type: "info",
        //     message: "已取消操作",
        //   });
        // });
      } else {
        this.dialogVisible = true;
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "afterList",
      //   };
      //   const res = await getAdminFieldIndex(params);
      this.tableHead = this.table_options.afterList;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;

      getAfterList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    getInfo(row) {
      this.$router.push({
        path: "/shopOrderMamage/afterInfo",
        query: {
          id: row.id,
          originOrder: "customer",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
