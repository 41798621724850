import request from '@/utils/request'

//订单列表
// admin/order
export function getOrderList(query) {
    return request({
        url: '/admin/order',
        method: 'get',
        params: query
    })
}
//订单详情
export function getOrderInfo(query) {
    return request({
        url: '/admin/order-commons/' + query.id,
        method: 'get',
        params: query
    })
}
//订单发货
export function orderSend(query) {
    return request({
        url: '/admin/order-send/' + query.id,
        method: 'put',
        data: query
    })
}
//售后订单列表
// admin/after-list
export function getAfterList(query) {
    return request({
        url: '/admin/after-list',
        method: 'get',
        params: query
    })
}
//售后订单详情
export function getAfterInfo(query) {
    return request({
        url: '/admin/after-info/' + query.id,
        method: 'get',
        params: query
    })
}
//售后订单退款
export function postExamine(query) {
    return request({
        url: '/admin/saleOrder/refund/' + query.id,
        method: 'post',
        params: query
    })
}
//售后订单再次审核
export function postExamineRefund(query) {
    return request({
        url: '/admin/saleOrder/examineRefund/' + query.id,
        method: 'post',
        params: query
    })
}

//订单列表退款
export function postRefund(data) {
    return request({
        url: '/admin/order/refund/' + data.id,
        method: 'post',
        data
    })
}
//售后订单导出
export function getAfterExport(query) {
    return request({
        responseType: 'blob',
        url: '/admin/after-export',
        method: 'get',
        params: query
    })
}
//自提点选择
// export function getPickList() {
//     return request({
//         url: '/admin/deliver-select',
//         method: 'get',
//     })
// }
export function getPickList(data) {
    return request({
        url: '/admin/deliver-select/',
        method: 'get',
        data
    })
}
//修改物流单号
export function updateExpressInfo(query) {
    return request({
        url: '/admin/updateExpressInfo/' + query.id,
        method: 'put',
        data: query
    })
}
//订单商品详情
export function getOrderGoodsInfo(data) {
    return request({
        url: '/admin/orderGoodsInfo/' + data.id,
        method: 'get',
        data
    })
}